// JavaScript Document

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars             } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes           } from "@fortawesome/pro-regular-svg-icons/faTimes";

import { faAngleRight as fasFaAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faAngleLeft as fasFaAngleLeft   } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes);

/**
 * Add solid icons
 */

library.add(fasFaAngleRight, fasFaAngleLeft);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
